var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-table"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"headers":_vm.headersData,"items":_vm.groupsList,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"hide-default-footer":"","item-key":"name"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',{staticClass:" d-sm-table-cell ",staticStyle:{"text-align":"start"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"group_image",attrs:{"src":item.image}}),_vm._v(" "+_vm._s(item.name)+" ")])]),_c('td',{staticClass:" d-sm-table-cell text-left"},[(item.users.length)?_c('div',{staticClass:"images_overlap"},[_vm._l((item.users),function(user){return _c('img',{key:user.id,staticClass:"group-img",attrs:{"src":user.avatar,"alt":""}})}),((item.users_count -item.users.length) >0)?_c('button',{staticClass:"more-imgs",attrs:{"aria-label":"add"}},[_vm._v(_vm._s(item.users_count -item.users.length)+"+")]):_vm._e()],2):_c('div',[_vm._v("لم يتم إضافة اى اعضاء")])]),_c('td',{staticClass:" d-sm-table-cell  text-left"},[_vm._v(" "+_vm._s(item.department)+" ")]),_c('td',[_c('v-row',{attrs:{"align":"center","justify":"content-center"}},[(!isExpanded)?_c('button',{staticClass:"mx-2 btn btn_success text-center",on:{"click":function($event){_vm.getSingleGroup(item.id) ;
                            expand(!isExpanded)}}},[_c('img',{staticStyle:{"width":"17px"},attrs:{"src":"/media/svg/plus_t.svg"}})]):_vm._e(),(isExpanded)?_c('button',{staticClass:"mx-2 btn btn_success text-center",on:{"click":function($event){return expand(!isExpanded)}}},[(!isExpanded)?_c('img',{staticStyle:{"width":"17px"},attrs:{"src":"/media/svg/plus_t.svg"}}):_vm._e(),(isExpanded)?_c('img',{staticStyle:{"width":"17px"},attrs:{"src":"/media/svg/mius.svg"}}):_vm._e()]):_vm._e(),_c('button',{staticClass:"mx-2 btn btn_edit text-center",on:{"click":function($event){return _vm.$router.push(("/edit-group/" + (item.id)))}}},[_c('img',{staticStyle:{"width":"17px"},attrs:{"src":"/media/svg/edit_metings.svg"}})]),_c('button',{staticClass:"mx-2 btn btn_share text-center",on:{"click":function($event){return _vm.SelectGroup(item,'share')}}},[_c('img',{staticStyle:{"width":"17px"},attrs:{"src":"/media/svg/share.svg"}})]),_c('button',{staticClass:"mx-2 btn btn_delete text-center",on:{"click":function($event){return _vm.SelectGroup(item,'delete')}}},[_c('img',{staticStyle:{"width":"17px"},attrs:{"src":"/media/svg/delete.svg"}})])])],1)])]}},{key:"expanded-item",fn:function(ref){
                            var headers = ref.headers;
                            var item = ref.item;
return [_c('td',{staticClass:"expanded-td",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"collapse_body d-flex"},[_c('div',{staticClass:"col-md-3"}),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"search-people"},[_c('span',{staticClass:"title d-block"},[_vm._v("البحث باسم الشخص")]),_c('v-select',{attrs:{"options":_vm.AllUsers,"placeholder":"البحث باسم الشخص","label":"name","reduce":function (user) { return user.id; }},on:{"input":_vm.AddUserInGroup,"search":_vm.onSearch},model:{value:(_vm.user_selected),callback:function ($$v) {_vm.user_selected=$$v},expression:"user_selected"}}),_c('span',{staticClass:"mt-2 gray-clr"},[_vm._v("إذا لم تجد الشخص الذي تبحث عنه يمكنك إضافته "),_c('a',{staticClass:"here",attrs:{"type":"button"},on:{"click":function($event){_vm.popupAddUser=true}}},[_vm._v("من هنا")])])],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"list-of-people"},[(_vm.singlrGroup.users.length)?_c('ul',{staticClass:"m-0"},_vm._l((_vm.singlrGroup.users),function(user){return _c('li',{key:user.id,staticClass:"d-flex justify-content-between"},[_c('span',{staticClass:"parent"},[_c('img',{attrs:{"src":user.avatar,"alt":""}}),_c('span',{staticClass:" "},[_vm._v(_vm._s(user.name))])]),_c('button',{on:{"click":function($event){return _vm.deleteUser(user.id)}}},[_c('img',{staticStyle:{"width":"17px"},attrs:{"src":"/media/svg/delete.svg"}})])])}),0):_c('ul',[_c('li',[_vm._v("لم يتم إضافة اى اعضاء")])])])]),_c('div',{staticClass:"col-md-3"})])])]}}])}),(_vm.popupAddUser)?_c('AddMembers',{attrs:{"popupAddUser":_vm.popupAddUser,"closePopup":_vm.closePopup,"getAllUser":_vm.getAllUser}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }