<template lang="html">
  <div class="main-table">
        <v-data-table
        :mobile-breakpoint="0" 
            :headers="headersData"
            :items="groupsList"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            hide-default-footer
            item-key="name"
            class="elevation-1"
        >
            <template  :mobile-breakpoint="0" v-slot:item="{ item, expand, isExpanded }">
                <tr>
                    <td class=" d-sm-table-cell " style="text-align:start">
                   
                        <div class="d-flex align-items-center">
                          <img class="group_image" :src="item.image"/>
                            <!-- <div class="mr_notation">{{item.name.substring(0,2)}}</div> -->
                            {{item.name}}
                        </div>
                    
                        
                    </td>
                    <td class=" d-sm-table-cell text-left">
                        <div v-if="item.users.length" class="images_overlap">
                            <img  v-for="user in item.users" :key="user.id"  class="group-img" :src="user.avatar" alt="" />
                          
                            <button v-if="(item.users_count -item.users.length) >0" aria-label="add" class="more-imgs">{{item.users_count -item.users.length}}+</button>
                        </div>
                        <div v-else>لم يتم إضافة اى اعضاء</div>
                    </td>
                    <td class=" d-sm-table-cell  text-left">
                        {{item.department}}
                    </td>
                    <td>
                      <v-row
                            align="center"
                            justify="content-center">
                            <button v-if="!isExpanded" class="mx-2 btn btn_success text-center" @click="
                           
                            getSingleGroup(item.id) ;
                            expand(!isExpanded)">
                               <img style="width: 17px" src="/media/svg/plus_t.svg" />
                            </button>
                              <button  v-if="isExpanded"  class="mx-2 btn btn_success text-center" @click="
                        
                            expand(!isExpanded)">
                                <!-- <i class="icon fas fa-plus p-0"></i> -->
                              <!-- .svg -->
                                 <img v-if="!isExpanded" style="width: 17px" src="/media/svg/plus_t.svg" />
                                   <img v-if="isExpanded" style="width: 17px" src="/media/svg/mius.svg" />
                            </button>
                            <button @click="$router.push(`/edit-group/${item.id}`)" class="mx-2 btn btn_edit text-center">
                              
                                <img style="width: 17px" src="/media/svg/edit_metings.svg" />
                                <!-- <i class="icon far fa-edit p-0"></i> -->
                            </button>
                             <button @click="SelectGroup(item,'share')" class="mx-2 btn btn_share text-center">
                                  <img style="width: 17px" src="/media/svg/share.svg" />
                            </button>
                              <button @click="SelectGroup(item,'delete')" class="mx-2 btn btn_delete text-center">
                                  <img style="width: 17px" src="/media/svg/delete.svg" />
                            </button>
                           
                         </v-row>
                    </td>
                </tr>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="expanded-td">
                    <div class="collapse_body d-flex">
                      <div class="col-md-3"></div>
                      <div class="col-md-3">
                        <div class="search-people">
                          <span class="title d-block">البحث باسم الشخص</span>
                          <v-select
                            @input="AddUserInGroup"
                            v-model="user_selected"
                            :options="AllUsers"
                            placeholder="البحث باسم الشخص"
                            label="name"
                            :reduce="(user) => user.id"
                            @search="onSearch"
                          />
                          <span class="mt-2 gray-clr"
                            >إذا لم تجد الشخص الذي تبحث عنه يمكنك إضافته
                            <a type="button" @click="popupAddUser=true" class="here"
                              >من هنا</a
                            ></span
                          >
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="list-of-people">
                          <ul v-if="singlrGroup.users.length" class="m-0">
                            <li
                              v-for="user in singlrGroup.users"
                              :key="user.id"
                              class="d-flex justify-content-between"
                            >
                              <span class="parent">
                                <img :src="user.avatar" alt="" />
                                <span class=" ">{{ user.name }}</span>
                              </span>
                              <button @click="deleteUser(user.id)">
                                <img style="width: 17px" src="/media/svg/delete.svg" />
                              </button>
                            </li>
                          </ul>
                          <ul v-else>
                            <li>لم يتم إضافة اى اعضاء</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-3"></div>
                    </div>
                  </td>
      </template>
</v-data-table>
  <AddMembers
      v-if="popupAddUser"
      :popupAddUser="popupAddUser"
      :closePopup="closePopup"
      :getAllUser="getAllUser"
    />
  </div>
</template>
<script>
import users from "../../http/requests/users";
import group from "../../http/requests/groups";
import vSelect from "vue-select";
import AddMembers from "../users/AddMembers";
export default {
  props: ["groupsList","SelectGroup"],
 
  data() {
    return {
      expanded: [],
      singleExpand: true,
       popupAddUser: false,
      AllUsers: [],
      singlrGroup: {},
      user_selected:{},
      headersData: [
        {
         
          text: "اسم المجموعة",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "أعضاء المجموعة", value: "members", sortable: false },
        { text: "الإدارة", value: "type" },
        { text: "", value: "actions" },
      ],
    };
  },
  methods: {
    onSearch(search, loading) {
     
      loading(true);
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (search) {
          users.getusers(1, search).then((res) => {
            this.AllUsers = res.data.data;
            loading(false);
          });
        } else {
          loading(false);
          this.getAllUser();
        }
      }, 500);
    },
         closePopup() {
      this.popupAddUser = false;
    },
    AddUserInGroup(value) {
      this.$vs.loading();  
      group
        .AddUsers(this.singlrGroup.id, {'users':[value]})
        .then(() => {
          this.getSingleGroup(this.singlrGroup.id)
          this.$vs.loading.close();
          this.user_selected={}
          this.$vs.notify({
            text: "تم إضافة العضو بنجاح",
            color: "success",
          });
        })
          .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
      
    },
      deleteUser(user_id) {
      this.$vs.loading();
      group
        .deleteUserFromgroup(this.singlrGroup.id,user_id)
        .then(() => {
          this.getAllUser();
          this.getSingleGroup(this.singlrGroup.id)
          this.$vs.loading.close();
          this.popupDelete = false;
          this.$vs.notify({
            text: "تم حذف العضو بنجاح",
            color: "success",
          });
        })
          .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
      
    },
    getAllUser(group_id) {
      this.AllUsers = [];
    
      users
        .add_users_group(group_id)
        .then((res) => {
          this.AllUsers = res.data.data;
          
        })
        .catch(() => {
         
        });
    },
    getSingleGroup(id) {
      this.singlrGroup = {};
      this.getAllUser(id),
      this.$vs.loading();
      group
        .getSinglegroup(id)
        .then((res) => {
          this.singlrGroup = res.data.data;
          this.$vs.loading.close();
        })
     .catch((err) => {
            this.$vs.loading.close();
            if(err.response.data.error){
              this.$vs.notify({
              text: err.response.data.error,
              color: "danger",
            });
            }
            else{
               this.$vs.notify({
              text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
              color: "danger",
            });
            }
           
          });
      }
  },
  // beforeRouteUpdate(to, from, next) {
  //   next();
  //   this.getAllUser();
  // },
  // created() {
  //   this.getAllUser();
  // },

  components: { "v-select": vSelect ,AddMembers},
};
</script>