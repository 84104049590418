
<template>
  <div class="groups" data-app>
       <div class="row page_header_all">
      <div class="col-md-6 col-12 right_content">
        <div class="page_title">
          <div class="title_icon">
            <img src="/media/svg/plus.svg" />
          </div>
          <h6 class="title">المجموعات</h6>
        </div>
        <div class="page_input_search position-relative">
          <input
                  @input="getAllGroups"
                  v-model="search_word"
                  class="name_search"
                  placeholder="بحث بالاسم"
                />
          <button class="search-icon">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>

      <div class="col-md-6 action_buttons">
        <button
          class="btn    btn_Green"
        
           @click="$router.push('/add-group')"
         
        >
          <span> إضافة مجموعة</span>
        </button>
      </div>
    </div>
        <ListData :groupsList="AllGroups" :SelectGroup="SelectGroup" />
        <paginate
          v-if="totalPages > 1"
          v-model="page"
          :pageCount="totalPages"
          :page-range="15"
          :clickHandler="clickCallback"
          :prevText="'الصفحه السابقة'"
          :nextText="'الصفحه التالية'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
     
  

    <!-- ADD USER && EDIT USER -->

    <!-- add to group -->
    <!-- <div class="centerx">
      <vs-popup
        class="holamundo"
        title="إضافة شخص الى مجموعة"
        :active.sync="popupActivo"
      >
        <div class="add_group">
          <div class="form_body">
            <div class="metting_info justify-content-center d-flex">
              <form class="row">
                <div class="form-group col-md-6 d-block">
                  <label for="group_name">اسم الشخص</label>
                  <input type="text" placeholder="اسم الشخص" id="group_name" />
                </div>
                <div class="form-group col-md-6 d-block">
                  <label for="group_name">رقم الموبايل</label>
                  <input
                    type="text"
                    placeholder="رقم الموبايل"
                    id="group_name"
                  />
                </div>
                <div class="form-group col-md-6 d-block">
                  <label for="group_name">البريد الإلكتروني</label>
                  <input
                    type="text"
                    placeholder="البريد الإلكترونى"
                    id="group_name"
                  />
                </div>
                <div class="form-group col-md-6 d-block">
                  <label for="group_name">اسم الشركة</label>
                  <input type="text" placeholder="اسم الشركة" id="group_name" />
                </div>
                <div class="form-group col-md-6 d-block">
                  <label for="group_name">المسمي الوظيفي</label>
                  <input
                    type="text"
                    placeholder="المسمي الوظيفي"
                    id="group_name"
                  />
                </div>

                <div class="form-group col-md-6 d-block">
                  <label for=""> صورة شخصية</label>
                  <input
                    hidden
                    type="file"
                    placeholder="صورة المجموعة"
                    id="avatar"
                    name="avatar"
                    accept="image/png, image/jpeg"
                  />
                  <label
                    aria-placeholder="رفع صورة للمجموعة"
                    class="photo_label"
                    for="avatar"
                  >
                    <div class="plus_img">
                      <i class="far fa-image"></i>
                    </div>
                  </label>
                </div>
                <div class="form-group col-md-12">
                  <label for="avatar"> إسم المجموعة</label>

                  <div class="input-search position-relative">
                    <multiselect
                      v-model="Search_metting_value"
                      placeholder="البحث في المجموعات"
                      label="title"
                      track-by="title"
                      :options="options"
                      :option-height="104"
                      
                      :show-labels="false"
                    >
                      <template slot="option" slot-scope="props">
                        <span class="parent">
                          <span class=" ">
                            {{ props.option.title }}</span
                          >
                        </span>
                      </template>
                    </multiselect>
                    <button class="search-icon">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="popoup_footer justify-content-between d-flex">
              <div class="d-flex">
                <vs-checkbox color="#1bb04e" style="height: 45px"></vs-checkbox>
                <h6 class="mt-5" style="font-size: 14px; color: #676767">
                  إضافة شخص اخر مع الاختفاظ بالشاشة الحالية
                </h6>
              </div>
              <button
                class="   btn_Green add-member tajawal-medium"
                @click="popupActivo = false"
                aria-label="saveasdraft"
              >
                <span>حفظ البيانات</span>
              </button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div> -->
    <!-- Remove popup -->
    <div class="centerx">
      <vs-popup
        class="holamundo"
        title="حذف المجموعة"
        :active.sync="popupDelete"
      >
        <div class="add_group form_info text-center">
          <p>هل انت متأكد من حذف المجموعة {{ selectedGroup.name }} ؟</p>
          <div class="popoup_footer justify-content-left d-flex">
            <button
              class="   btn_Green"
              @click="deleteGroup()"
              aria-label="saveasdraft"
            >
              <span>حذف</span>
            </button>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- Share Group -->
    <div class="centerx">
      <vs-popup
        class="holamundo"
        title="مشاركه المجموعة"
        :active.sync="popupShare"
      >
        <div class="popoup_share form_info">
          <p>
            قم بمشاركة رابط المجموعة ليتمكن اعضاء فريقك من تسجيل الدخول
            والإنضمام لمجموعتك الأن
          </p>
          <div class="content">
            <h5 class="text-end">قم بمشاركة المجموعة عن طريق</h5>
            <ul class="icons text-center">
              <facebook class="icon_btn" :url="url" scale="3"></facebook>
              <twitter class="icon_btn" :url="url" scale="3"></twitter>
              <linkedin class="icon_btn" :url="url" scale="3"></linkedin>
              <telegram class="icon_btn" :url="url" scale="3"></telegram>
              <whats-app class="icon_btn" :url="url" scale="3"></whats-app>
              <email class="icon_btn" :url="url" scale="3"></email>
            </ul>
            <p class="text-left">او قم بنسخ الرابط التالى</p>
            <div class="field">
              <i class="url-icon uil uil-link"></i>
              <input type="text" :value="url" readonly />
              <button
                v-clipboard:copy="`${url}`"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                class="btn_Green btn_main"
              >
                نسخ
              </button>
            </div>
          </div>
          <!-- <div class="popoup_footer justify-content-left d-flex">
            <button
              class="   btn_Green add-member"
              @click="deleteGroup()"
              aria-label="saveasdraft"
            >
              <span>حذف</span>
            </button>
          </div> -->
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import groups from "../../../http/requests/groups";
import users from "../../../http/requests/users";
import ListData from "@/components/groups/List";
import {
  Facebook,
  Twitter,
  Linkedin,
  Telegram,
  WhatsApp,
  Email,
} from "vue-socialmedia-share";
export default {
  name: "Groups",
  components: {
    ListData,
    Facebook,
    Twitter,
    Linkedin,
    Telegram,
    WhatsApp,
    Email,
  },
  data() {
    return {
      search_word: null,
      totalPages: 0,
      page: 1,
      AllGroups: [],
      AllRoles: [],
      previewImage: null,
      user_photo: "",
      form: {},

      popupDelete: false,
      popupShare: false,
      selectedGroup: {},
      popupEdit: false,
      value: null,

      data: [],
      select1: null,
      select2: null,
      Search_metting_value: null,
      base_URL: "",
      url: "",
    };
  },
  methods: {
    onCopy(text) {
      this.$vs.notify({
        text: "تم نسخ الرابط بنجاح",
        color: "success",
      });
    },
    clickCallback(pageNum) {
      this.page = pageNum;
      this.getAllGroups();
    },

    getUserRoles() {
      this.AllRoles = [];

      users
        .getuserRoles()
        .then((res) => {
          this.AllRoles = res.data.data;
          this.$vs.loading.close();
        })
      .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
    
      },

    getAllGroups() {
      this.AllGroups = [];
      this.$vs.loading();
      groups
        .getgroups(this.page, this.search_word)
        .then((res) => {
          this.AllGroups = res.data.data;
          this.totalPages = res.data.meta.last_page;
          this.$vs.loading.close();
        })
        .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      },
    deleteGroup() {
      this.$vs.loading();
      groups
        .deleteSinglegroup(this.selectedGroup.id)
        .then(() => {
          this.getAllGroups();
          this.$vs.loading.close();
          this.popupDelete = false;
          this.$vs.notify({
            text: "تم حذف المجموعة بنجاح",
            color: "success",
          });
        })
       .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      },
    SelectGroup(group, type) {
      if (type == "share") {
        this.popupShare = true;
        this.selectedGroup = group;
        this.url = this.base_URL + "/#/join/" + group.slug;
      } else {
        this.popupDelete = true;
        this.selectedGroup = group;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.getAllGroups();
    this.getUserRoles();
  },
  created() {
    this.getAllGroups();
    this.getUserRoles();
    this.base_URL = window.location.origin;
  },
};
</script>
<style scoped lang="scss">
.popoup_share {
  .content {
    p {
      font-size: 16px;
    }
    .icons {
      display: flex;
      justify-content: center;
      .icon_btn {
        height: 50px;
        width: 50px;
        cursor: pointer;
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          font-size: 23px;
          padding: 8px;
        }
      }
    }
    .field {
      position: relative;
      input {
        border: 1px solid #e5e5e5;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
        text-align: end;
        padding-left: 106px;
      }

      button {
        position: absolute;
        left: 5px;
        top: 5px;
        padding: 8px 20px;
      }
    }
  }
}
</style>
