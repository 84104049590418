import axios from '../axios/index.js'
export default {
    getgroups(page = 1,search) {
        var keyword=''
        if(search){
           keyword= '&keyword='+search
        }
        else{
            keyword= ''
        }
        return axios.get(`/groups?page=${page}`+keyword)
    },
    getSinglegroup(id){
        return axios.get(`/groups/${id}`)
    },
    Updategroup(id, data) {
        return axios.post(`/groups/${id}`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    Creategroup(data) {
        return axios.post(`/groups`, data,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    deleteSinglegroup(id) {
        return axios.delete(`/groups/${id}`)
    },
    AddUsers(id,data) {
        return axios.post(`/add_users_group/${id}`, data)
    },
    deleteUserFromgroup(id,user_id) {
        return axios.delete(`/delete_user_from_group/${id}/user/${user_id}`)
    },
}